@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");
.button {
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.25;
  padding: .5rem 1rem;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

.button:hover {
  text-decoration: none; }

a.button {
  display: block;
  text-decoration: none; }

.primary-foreground {
  color: #653308; }

.primary-background {
  background-color: #653308;
  color: #FFFFFF; }

.secondary-background {
  background-color: #2E7D32;
  color: #FFFFFF; }

.button.secondary-background:hover {
  background-color: #1B5E20; }

.secondary-border,
.secondary-border:active,
.secondary-border:focus,
.secondary-border:hover {
  border-color: #2E7D32; }

.callout,
.secondary-foreground {
  color: #2E7D32; }

p a, li a, .readable a {
  color: #2E7D32;
  font-weight: 700; }

p a:visited, li a:visited {
  color: #1B5E20; }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

body {
  color: #000;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
  line-height: 1.5; }

h2, h3, h4, h5 {
  font-weight: 400; }

@media (max-width: 720px) {
  body {
    font-size: 14px; } }

.sans {
  font-family: 'Roboto', sans-serif; }

.callout {
  font-weight: 700; }

.center {
  text-align: center; }

.lg-title {
  font-size: 2em; }

@media (max-width: 850px) {
  .lg-title {
    font-size: 1.5em; } }

/**
 * Default counters and styles are provided for five levels of nesting.
 * If you require nesting beyond that level, you should use your own CSS
 * classes to do so. If you care about handling RTL languages, the rules you
 * create should look a lot like these.
 */
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 0em; }

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 0em; }

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em; }

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em; }

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 3em; }

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 3em; }

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em; }

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em; }

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 6em; }

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 6em; }

/**
 * Only use `square` list-style after the first two levels.
 */
.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative; }

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc; }

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle; }

/**
 * Ordered list item counters are managed with CSS, since all list
 * nesting is
 * purely visual.
 */
.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px; }

/**
 * Counters are reset in JavaScript. If you need different
 * counter styles,
 * override these rules. If you need more nesting, create
 * your own rules to
 * do so.
 */
.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3; }

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4; }

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0; }

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1; }

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2; }

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3; }

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4; }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

.About {
  padding-top: 20px; }

.About-legal-notice {
  background-color: #ebebeb;
  margin-bottom: 20px;
  padding: 20px 30px;
  text-align: justify;
  max-width: 960px !important; }

.About-subtitle {
  font-size: 1.5em;
  padding-bottom: 20px; }

.About-body {
  padding-top: 20px; }

.About-body-nav {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 20px; }

@media (max-width: 720px) {
  .About-subtitle {
    font-size: 1em; }
  .About-image-hero {
    height: 175px; } }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px; }

.readable {
  margin-right: auto;
  max-width: 50em; }

.pseudoParagraph,
.readable p,
.readable li {
  font-size: 1.2em;
  line-height: 1.8;
  margin: 1.5em 0; }

.overflow-hidden {
  overflow: hidden; }

.Loading {
  height: 100vh; }

@media (max-width: 1020px) {
  .container {
    padding-left: 10px;
    padding-right: 10px; } }

.primary-foreground {
  color: #653308; }

.primary-background {
  background-color: #653308;
  color: #FFFFFF; }

.secondary-background {
  background-color: #2E7D32;
  color: #FFFFFF; }

.button.secondary-background:hover {
  background-color: #1B5E20; }

.secondary-border,
.secondary-border:active,
.secondary-border:focus,
.secondary-border:hover {
  border-color: #2E7D32; }

.callout,
.secondary-foreground {
  color: #2E7D32; }

p a, li a, .readable a {
  color: #2E7D32;
  font-weight: 700; }

p a:visited, li a:visited {
  color: #1B5E20; }

.ContentMedia-dropzone {
  border: 1px dashed #888;
  border-radius: 5px;
  color: #888;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  user-select: none; }
  .ContentMedia-dropzone:hover {
    background-color: #ccc;
    color: black; }

.ContentMedia-dropzone-active {
  background-color: #ccc;
  color: black; }

.ContentMedia-media {
  max-height: 300px;
  overflow-y: auto; }

.ContentMedia-image-container {
  border: 1px solid #ccc;
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
  width: 200px; }

.ContentMedia-image {
  width: 100%; }

.ContentMedia-PDF-link {
  cursor: pointer; }
  .ContentMedia-PDF-link:hover {
    color: #2E7D32; }

.primary-foreground {
  color: #653308; }

.primary-background {
  background-color: #653308;
  color: #FFFFFF; }

.secondary-background {
  background-color: #2E7D32;
  color: #FFFFFF; }

.button.secondary-background:hover {
  background-color: #1B5E20; }

.secondary-border,
.secondary-border:active,
.secondary-border:focus,
.secondary-border:hover {
  border-color: #2E7D32; }

.callout,
.secondary-foreground {
  color: #2E7D32; }

p a, li a, .readable a {
  color: #2E7D32;
  font-weight: 700; }

p a:visited, li a:visited {
  color: #1B5E20; }

.ContentNav {
  border: 1px solid #CCC;
  border-radius: 3px;
  flex-shrink: 0;
  height: 100%;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
  width: 250px; }

.ContentNav-header {
  font-size: 1.2em;
  padding: 10px;
  text-align: center; }

.ContentNav-link,
.ContentNav-link-active {
  border-top: 1px solid #CCC;
  color: #2E7D32;
  display: block;
  padding: 5px 10px 5px 12px;
  text-decoration: none; }

.ContentNav-link:hover,
.ContentNav-link-active {
  border-left: 2px solid #653308;
  color: #653308;
  font-weight: bold;
  padding-left: 10px; }

@media (max-width: 980px) {
  .ContentNav {
    margin-left: 0;
    max-width: 50em;
    width: 100%; } }

.ContentPiece {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px; }

.ContentPiece-content {
  flex-grow: 1; }

.ContentPiece-image-container {
  text-align: center; }

.ContentPiece-image {
  width: 100%; }

.about .ContentPiece-image {
  max-width: 100%;
  width: auto; }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

.DonateCTA {
  max-width: 350px; }

.DonateCTA h2, .DonateCTA h3 {
  padding-bottom: 10px; }

.DonateCTA-disclaimer {
  font-size: 12px; }

@media (max-width: 720px) {
  .DonateCTA {
    max-width: none; } }

.primary-foreground {
  color: #653308; }

.primary-background {
  background-color: #653308;
  color: #FFFFFF; }

.secondary-background {
  background-color: #2E7D32;
  color: #FFFFFF; }

.button.secondary-background:hover {
  background-color: #1B5E20; }

.secondary-border,
.secondary-border:active,
.secondary-border:focus,
.secondary-border:hover {
  border-color: #2E7D32; }

.callout,
.secondary-foreground {
  color: #2E7D32; }

p a, li a, .readable a {
  color: #2E7D32;
  font-weight: 700; }

p a:visited, li a:visited {
  color: #1B5E20; }

.Editor-editor {
  cursor: text;
  height: 80vh;
  overflow-y: scroll; }

.Editor-editor .public-DraftEditorPlaceholder-root,
.Editor-editor .public-DraftEditor-content {
  min-height: 100px;
  padding: 15px; }

.Editor-blockquote {
  background-color: #EFEBE9;
  border-left: 5px solid #653308;
  color: #333;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.Editor-editing-container {
  margin-left: 300px; }

.Editor-controls {
  position: fixed;
  width: 260px; }

.Editor-text-controls {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding-top: 5px;
  user-select: none; }

.Editor-styleButton {
  color: #999;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 2px 0;
  display: inline-block; }
  .Editor-styleButton:hover {
    color: #2E7D32; }

.Editor-activeButton {
  color: #2E7D32;
  font-weight: bold;
  text-decoration: underline; }

.Editor-edit-button {
  cursor: pointer;
  float: right; }
  .Editor-edit-button:hover {
    color: #2E7D32; }

.Editor-persist-control-item {
  cursor: pointer;
  padding-right: 5px;
  text-decoration: underline; }
  .Editor-persist-control-item:hover {
    color: #2E7D32; }

.Editor-section-title {
  font-size: 2em; }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

.Footer {
  padding: 20px 0; }

.Footer-content {
  display: flex;
  justify-content: space-between; }

.Footer-title {
  font-weight: 700;
  padding-bottom: 10px; }

.Footer-addresses {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px; }

.Footer-address:last-child {
  margin-left: 35px; }

.Footer-donate-mobile {
  padding-bottom: 20px; }

@media (max-width: 720px) {
  .Footer {
    padding: 10px 0; }
  .Footer-content {
    display: block; } }

@media (max-width: 720px) {
  .no-phone {
    display: none; } }

@media (min-width: 720px) {
  .only-phone {
    display: none; } }

.Header {
  border-bottom: 1px solid #ccc; }

.Header.container {
  max-width: 1020px; }

.Header-content {
  align-items: center;
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
  padding: 35px 10px 10px; }

.Header-content-item {
  margin-right: 5px; }

.Header-title {
  align-items: center;
  display: flex;
  text-decoration: none; }

.Header-nav {
  font-size: 0.9em; }

.logo {
  height: 68px;
  width: 58px; }

@media (max-width: 1020px) {
  .Header-content {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 720px) {
  .Header {
    border: none; }
  .Header-content {
    display: block;
    padding: 0; }
  .Header-nav {
    font-size: 0.7em; } }

.MissionAndVision-mission {
  padding: 15px 0; }

.MissionAndVision-vision > span {
  color: #000;
  font-weight: 300; }

.MissionAndVision-image-text-container {
  display: flex; }

.MissionAndVision-image > img {
  width: 500px; }

.MissionAndVision-text > ul {
  font-size: 1.2em; }

.MissionAndVision-text.left {
  padding-right: 20px; }

.MissionAndVision-text.right {
  padding-left: 20px; }

@media (max-width: 850px) {
  .MissionAndVision-image-text-container {
    display: block; }
  .MissionAndVision-image > img {
    width: 100%; }
  .MissionAndVision-text.left {
    padding-right: 0; }
  .MissionAndVision-text.right {
    padding-left: 0; } }

.primary-foreground {
  color: #653308; }

.primary-background {
  background-color: #653308;
  color: #FFFFFF; }

.secondary-background {
  background-color: #2E7D32;
  color: #FFFFFF; }

.button.secondary-background:hover {
  background-color: #1B5E20; }

.secondary-border,
.secondary-border:active,
.secondary-border:focus,
.secondary-border:hover {
  border-color: #2E7D32; }

.callout,
.secondary-foreground {
  color: #2E7D32; }

p a, li a, .readable a {
  color: #2E7D32;
  font-weight: 700; }

p a:visited, li a:visited {
  color: #1B5E20; }

.Nav {
  align-items: center;
  display: flex; }

a.Nav-link,
span.Nav-link,
a.Nav-link-active,
span.Nav-link-active,
a.SearchBox-link {
  color: inherit;
  cursor: pointer;
  font-weight: normal;
  margin-right: 10px;
  padding: 9px 0;
  text-decoration: none;
  white-space: nowrap; }

.Nav-link:active,
.Nav-link:focus,
.Nav-link:hover,
.Nav-link-active {
  border-bottom: 2px solid #2E7D32;
  padding-bottom: 7px; }

.NotFound {
  align-items: center;
  display: flex;
  min-height: 50vh; }

.People-img {
  max-height: 350px;
  max-width: 100%; }

.Youtube-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  position: relative; }

.Youtube-iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5 {
  margin: 0; }
